@import './ngx-theme';
@import './mix';
@import './common';
@import './var';
@import './reset';
@import './material-styling';
@import './buttons';
@import './table';
@import './typography';
.filter-col-head{
    padding:15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:#fff;
    a{
        
        img{
            transition:0.3s ease-in;
            transform: rotate(180deg);
        }
        &.isRotate{
            img{
                transform: rotate(0deg);
            }
        }
    }
    .label-icon-wrap{
        display: flex;
        align-items: center;
        img{
            margin-right:15px;
        }
    }
}
.filter-area{
    height:0;
    overflow: hidden;
    visibility: hidden;
    padding:0 20px 0;
    background: #EFF0F6;
    &.isOpen{
        height:auto;
        overflow: auto;
        visibility: visible;
        padding:0 20px 10px;
        &.radio-list{
            padding-top:15px;
        }
    }
}
.rating-and-reviews{
    .top-info{
        margin-bottom:15px;
        .flex-row{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .flex-col{
                width:250px;
                flex:0 250px;
                margin:0 15px 15px 0;
                .col-inner{
                    width: 250px;
                    height: 150px;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    .info-wrap{
                        height:100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        h3{
                            margin-bottom:5px;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 1.2;
                            text-align: center;
                            color: #000000;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            span{
                                font-size:14px;
                            }
                        }
                        h4{
                            margin-bottom:5px;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 1.2;
                            text-align: center;
                            color: #000000;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        p{
                            text-align: center;
                            font-size: 16px;
                            line-height: 1.2;
                            color: #000000CC;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
    }
    .rating-reviews-listing{
        .rating-review-item{
            padding: 10px;
            margin-bottom: 15px;
            background: #FAFAFA;
            border: 0.2px solid #c2c2c2;
            border-radius:5px;
            h3{
                font-weight: 700;
                font-size: 16px;
                line-height: 1.2;
                margin-bottom:5px;
                .rating{
                    padding:5px 10px;
                    display:inline-block;
                    margin-left:50px;
                    font-size:12px;
                    color:#fff;
                    background:#000000;
                }
            }
            p{
                font-weight: 500;
                font-size: 14px;
                line-height: 1.2;
                &.red{
                    color:red;
                }
                &.green{
                    color: green;
                }
            }
        }
    }
}
.cash-collected-details{
    .top-info{
        margin-bottom:0;
        .info-wrap{
            width: 250px;
            height: 150px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h3{
                margin-bottom:5px;
                font-weight: 700;
                font-size: 24px;
                line-height: 1.2;
                text-align: center;
                color: #000000;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                span{
                    font-size:14px;
                }
            }
            h4{
                margin-bottom:5px;
                font-weight: 700;
                font-size: 20px;
                line-height: 1.2;
                text-align: center;
                color: #000000;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            p{
                text-align: center;
                font-size: 16px;
                line-height: 1.2;
                color: #000000CC;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}
table{
    .mat-checkbox{
        margin-bottom:0;
    }
}
.restaurnat-item{
    height:80px;
    border: 0.2px solid #c2c2c2;
    padding:10px;
    span{
        display: flex;
        align-items: center;
    }
}
@import '../scss/mix';
@import '../scss/var';

.btn {
    padding: 14px 12px !important;
    border: none!important;
    color: $primary-light;
    background: transparent;
    transition: 0.2s;
    white-space: nowrap;
    min-width: 100px;
    width: auto;
    font-size: 16px;
    font-weight:700;
    border-radius: 5px;
    @include fpos(center, center);
    user-select: none;
    cursor: pointer;
    margin-right:15px;
    @media screen and (max-width: 568px) {
        padding: 6px 10px;
        font-size: 12px;
        min-width: 70px;
    }

    &.small {
        height: auto;
        padding: 6px 10px;
        line-height: 21px;
        border-radius: 5px;
        font-size: 14px;
    }

    &.w-100 {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    // Colors
    &.btn-primary {
        min-width:100px;
        color: $white;
        background-color: $primary-light;
    }

    &.btn-simple {
        min-width:100px;
        border-color: $btnBorder;
        color: #444444;
        background-color: $scrips-bg;
    }

    &.btn-green {
        min-width:100px;
        color: $white;
        background-color: $green;
        border-color: $green;
    }

    &.btn-green-outline {
        min-width:100px;
        background-color: transparent;
        color: $green;
        border-color: $green;
    }

    &.btn-red {
        min-width:100px;
        color: $white;
        background-color: $red;
        border-color: $red;
    }

    &.btn-red-outline {
        min-width:100px;
        background-color: transparent;
        color: $red;
        border-color: $red;
    }

    &.btn-warn {
        min-width:100px;
        color: $white;
        background-color: $warn;
        border-color: $warn;
    }

    &.btn-warn-outline {
        min-width:100px;
        background-color: transparent;
        color: $warn;
        border-color: $warn;
    }

    i {
        margin-right: 8px;
        font-size: 15px;

        @media screen and (max-width: 1600px) {
            font-size: 13px;
        }
    }

    .mat-badge-content {
        background: $layout-bg;
        color: $black;
        box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.25);
        left: -6px !important;
        top: -13px !important;
        height: 20px;
        width: 20px;
        line-height: 20px;
    }

    &.btn-indicator-cover {
        position: relative;

        .button-indicator {
            height: 8px;
            right: 3px;
            top: -13px;
            width: 8px;
            background: $primary;
            position: absolute;
            border-radius: 50%;
        }
    }

    .pulse_effect {
        border-radius: 50%;
        position: absolute;
        height: 14px;
        width: 14px;
        left: -10px;
        top: -3px;
        right: 0;
        bottom: 0;
        transform: rotateX(0deg);

        &:after {
            content: "";
            border-radius: 50%;
            height: 100%;
            width: 100%;
            position: absolute;
            animation: pulse 0.65s ease-out;
            animation-iteration-count: infinite;
            box-shadow: 0 0px 4px 3px rgba(0, 0, 0, 0.4);
            animation-delay: 0s;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(0.5, 0.5);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1, 1);
            opacity: 0;
        }
    }
    &:focus {
        outline: none;

        .mat-button-focus-overlay {
            background-color: transparent;
        }
    }
    &.disabled {
        color:#5b5a5a!important;
        background:#efefef;
        pointer-events: none;
    }
}

.btn-row {
    @include fpos(center, center);

    button {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

.btn:disabled,
.btn.disabled {
    cursor: not-allowed;
    pointer-events: none;
}
i {
    &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button {
    font-weight: 400;
    height: auto;
    padding: 14px 12px !important;
    line-height: initial !important;
    @media screen and (max-width: 568px) {
        padding: 6px 10px !important;
        font-size: 12px !important;
        border-radius: 3px;
        min-width: 70px;
    }
}
$primary: #e11f25;
$primary-light: #e11f25;
$primary-green: #44b749;

$secondary-dark: #002a65;
$secondary: #687eaf;
$secondary-light: #c7d9ee;

$social-media-blue: #002a65;
$social-media-yellow: #e11f25;
$social-media-yellow-light: #e11f25;
$social-media-blue: #01cb3b;
$social-media-blue-light: #61faa2;

$layout-bg: #edf0f2;
$input-border: #a5a5a5;
$input-bg: #ffffff;
$border-color: #cedceb;
$background-color: #f8fafc;
$scrips-bg: #f5f5f5;
$watchlist-filter-bg: #f0f5f8;

$alice-color: #0d4593;
$blue-color: #276ab4;

$red: #ff5722;
$green: #4aaf39;
$orange: #fb8c00;
$info: #25a9e0;
$light-gray: #eaeaea;
$warning: #ffc107;
$status-green: #1bc5bd;
$status-red: #f64e60;
$warn: #f44336;
$error: #ce0101;
$success: #0d8c69;


$white: #ffffff;
$black: #202020;
$yellow: #e8e759;
$blue: #3073b9;
$gray: #979797;
$grey: #8a8a8a;
$regentGray: #8d98a1;
$link: #e17582;
$lightBlue: #1b61b6;
$silver: #c7c9cb;
$lightGreen: #e5ffe8;
$pink: #ffecec;
$textColor: #333333;
$alto: #d3d3d3;
$darkGray: #585858;
$btnBorder: #9e9e9e;
$seaGreen: #08575b;
$purple: #3e3f5e;
$infoColor: #4f95cc;
$boulder: #757575;
$pinkSwan: #beb4b4;
$submarine: #b5bdc2;
$bgWhite: #f9f9f9;
$lightGrey: #93a1b5;

$mat-menu-side-padding: 14px !default;
$scrollbar-width: 17px;
$clear-button-width: 20px;
@import '../scss/mix';
@import '../scss/var';

.table-cover {
    width: 100%;
    margin: auto;
    overflow: auto;
    position: relative;
    border-radius: 5px;
    border: 1px solid $border-color;
    @include custom-scroller("x");

    &.simple-table {
        border: none;

        table {
            tr {
                background-color: transparent;
                border: none;
            }

            th,
            td {
                border: none !important;

                &:first-child {
                    padding-left: 20px;
                }

                &:last-child {
                    padding-right: 20px;
                }
            }

            tbody {
                tr {
                    border-top: 1px solid ;
                }
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th,
        .mat-header-cell,
        td,
        .mat-cell {
            padding: 10px !important;
            text-align: left;
            max-width: 300px;
            vertical-align: middle;
            font-size: 12px;

            &:not(:first-child) {
                border-left: 1px solid $border-color;
            }
        }

        thead {
            tr {
                width: 100%;
                background-color: $background-color !important;

                th,
                .mat-header-cell {
                    font-weight: bold;
                    height: 50px;
                    white-space: nowrap;
                    color: $black;
                    border: none;
                }
            }
        }

        tbody {
            tr {
                &:not(:last-child) {

                    td,
                    .mat-cell {
                        border-bottom: 1px solid $border-color !important;
                    }
                }

                td,
                .mat-cell {
                    height: 45px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 300px;
                    color: $gray;

                    &:first-child {
                        padding-left: 10px;
                        border-radius: 5px 0px 0px 5px;
                    }

                    &:last-child {
                        padding-right: 10px;
                        border-radius: 0px 5px 5px 0px;
                    }
                }
            }
        }
    }
}

.table-btn-action-cover {
    @include fpos(center, center);

    .btn {
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 576px) {
    table {
        th {
            &:first-child {
                padding-left: 5px;
            }

            &:last-child {
                padding-right: 5px;
            }
        }

        td {
            &:first-child {
                padding-left: 5px;
            }

            &:last-child {
                padding-right: 5px;
            }
        }

        thead {
            tr {
                th {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-weight: bold;
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    table {
        tbody {
            tr {
                td {
                    padding: 8px 10px;
                    font-size: 12px;
                }
            }
        }
    }
}
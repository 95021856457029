@import '../scss/var';
@import '../scss/mix';

._disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.space-between {
    @include fpos(center, space-between);
}

.flex-center {
    @include fpos(center, center);
}

.flex-start {
    @include fpos(flex-start, flex-start);
}

.justify-start {
    @include fpos(center, flex-start);
}

.align-flex-end {
    @include fpos(flex-end, center);
}

.flex-wrap {
    flex-wrap: wrap;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
}

.bold {
    font-weight: 600;
}

.thin {
    font-weight: 400;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.pointer {
    cursor: pointer;
}

.text-primary {
    color: $primary;
}

.text-gray {
    color: $gray;
}

.text-green {
    color: $green;
}

.text-red {
    color: $red;
}

.text-orange {
    color: $orange;
}

.border {
    border: 1px solid $border-color;
}

.box-shadow {
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.card-ui {
    min-width: 250px;
    max-width: 100%;
    box-shadow: 0px 0px 5px 3px rgba(79, 79, 79, 0.15);
    border-radius: 3px;
    overflow: hidden;
    background: $white;

    &.no-shadow {
        box-shadow: none;
    }

    .card-header {
        background-color: $background-color;
        padding: 15px;

        h5 {
            color: $black;
            margin: 0px;
            font-weight: bold;
        }
    }

    .card-body {
        padding: 15px 15px 20px;
    }

    .card-footer {
        .btn-cover {
            margin: 0px;
        }
    }
}
img{
    max-width:100%;
    height: auto;
    display: block;
}

@import "./scss/global-style";
@import "~@ng-select/ng-select/themes/default.theme.css";
.password-hint-menu {
    padding: 15px !important;
    max-width: 300px !important;
    min-height: auto !important;
}
.cdk-overlay-pane {
    max-width: 95vw !important;
    height: auto !important;
    max-height: calc(100vh - 50px);
    .mat-dialog-container {
        background-color: $white;
        overflow: hidden;
        color: $black;
    }
}
.highcharts-title {
    display: none;
}
.timepicker-overlay {
    z-index: 9999 !important;
}
.timepicker__header {
    background-color: $primary !important;
}
.timepicker-button {
    color: $primary !important;
}
.showLessWidthDotDot {
    @include text-truncate(1);
}
button{
    cursor: pointer;
}
.collapsed{
    &.showing-icons-on-collapse{
        .page-sidebar{
            .sidebar-list{
                padding:20px 5px;
            }
            .mat-list-item-content{
                padding: 0px 5px 0px 5px!important;
                .menu-text {
                    opacity:0!important;
                }
            }
        }
    }
}
.active{
    color:#0d8c69;
}
.inactive{
    color:#ce0101;
}
textarea.mat-input-element{
    resize: none!important;
}
.filter-area{
    .mat-form-field{
        margin-bottom:0;
    }
}
.action-right{
    button{
        .mat-button-focus-overlay{
            background:#fff;
        }
    }
    .mat-button-wrapper{
        display: flex;
        align-items: center;
        i{
            margin-right:5px;
        }
        img{
            margin-right:5px;
        }
    }
}
.mat-select-panel{
    &.customSelect{
        margin-top:50px;
    }
}
.direction-rtl{
    text-align: right!important;
    direction: rtl;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.rtl-editor{
    .ql-editor{
        direction: rtl;
        text-align: right!important;
        *{
            text-align: right!important;
        }
    }
}
ngx-material-timepicker-container{
    position: relative;
    z-index:999999;
}
.resend-btn{
    font-size:0;
    img{
        width:16px;
        height:16px;
    }
}
.mat-chip-list-wrapper{
    .mat-standard-chip{
        margin:0 4px!important;
    }
    input.mat-input-element{
        margin:0 4px 4px !important;
    }
}
.showing-icons-on-collapse{
    .page-sidebar{
        .mat-expansion-panel{
            .mat-expansion-panel-body{
                padding:0!important;
            }
            .mat-expansion-panel-header {
                padding:0 5px!important;
                &.mat-expanded{
                    padding:0 5px!important;
                }
                .mat-expansion-indicator{
                    display:none;
                }
                .mat-expansion-panel-header-title{
                    .menu-text{
                        display:none;
                    }
                }
            }
        }
    }
}
.filter-location-icon{
    width:20px;
}
mat-label{
    color:#a5a5a5;
    opacity:1;
    .astrick{
        color: #ff5722;
        position: relative;
        left: 3px;
        top: 2px;
        font-size: 14px;
        font-weight: 700;
        opacity:1;
    }
}
.astrick{
    color: #ff5722;
    position: relative;
    left: 3px;
    top: 2px;
    font-size: 14px;
    font-weight: 700;
    opacity:1;
}
.download-qr-code{
    display: flex;
    align-items: center;
    justify-content: center;
    .bshadow{
        width:100px;
        height:100px;
        margin: 0 auto 12px;
        border: 1px solid #000000;
        border-radius:3px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img{
            width:100%;
            height:100%;
            border-radius:3px;
            object-fit: contain;
            object-position: center center;
        }
    }
        .qrcode {
            width: 100px;
            height: 100px;
            margin: 0 auto 12px;
            border: 1px solid #000000;
            border-radius: 3px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
    
            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                object-fit: contain;
                object-position: center center;
            }
        }
}
@import '../scss/mix';
@import '../scss/var';

* {
    outline: none;
    font-family: 'Mulish', sans-serif;
    word-break: break-word;
    list-style: none;
    margin: 0;
    padding: 0;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

// For Collapsed Sidebar
body {
    margin: 0;
    text-align: left;
    background-color: $white;
    color: $black;

    &.collapsed {
        .main-header {
            .left-portion {
                .hamburger-icon {
                    @media screen and (max-width: 576px) {
                        display: flex !important;
                    }
                }
            }
        }

        .sidebar-wrap {
            transform: translateX(-300px);

            @media screen and (max-width: 576px) {
                transform: translateX(-102%);
            }
        }

        .main-content {
            width: 100% !important;
            left: 0 !important;
        }

        // For showing icons on sidebar collapsed
        &.showing-icons-on-collapse {
            @media screen and (min-width: 993px) {
                .sidebar-wrap {
                    transform: none !important;
                    width: 60px !important;

                    .page-sidebar {
                        .mat-list-item {
                            overflow: hidden;
                        }

                        .company-info {
                            display: none;
                        }
                    }
                }

                .main-content {
                    width: calc(100% - 60px) !important;
                    left: 60px !important;
                }
            }
        }
    }

    &.large-fonts {

        .link-item,
        a {
            font-size: 16px !important;
        }

        .btn {
            font-size: 15px !important;
        }

        table {

            th,
            td {
                font-size: 14px !important;
            }
        }
    }
}

.cdk-overlay-pane {
    &.openState {
        @media screen and (max-width: 576px) {
            margin: auto !important;
            max-width: 90vw !important;
        }
    }
}

.mat-dialog-container {
    padding: 0px !important;
}

a {
    text-decoration: none;
    color: $primary;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;

    @media screen and (max-width: 576px) {
        font-size: 12px;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.new-password {
    .mat-form-field-flex {
        padding-right: 40px !important;

        .app-invalid-password-hint {
            position: absolute;
            right: -52px;
            top: 12px;
        }
    }
}

.password-hint-menu {
    padding: 20px !important;

    .mat-menu-content {
        padding: 20px !important;

        ul {
            margin-bottom: 0px !important;
        }
    }
}

.pass-hint-cover {
    .mat-form-field-suffix {
        width: 55px;
        @include falign(center);

        .info-icon {
            margin-left: 5px;
        }
    }
}

.mat-datepicker-toggle {
    .mat-icon-button {
        display: flex !important;
        @include fpos(center, center);
        margin-right: -7px;
    }
}

button:focus {
    outline: none;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.bg-white {
    background-color: $white;
}

.btn-cover {
    @include fpos(center, center);
    flex-wrap: wrap;
    margin: 10px 0px 0px;
}

.place-order-modal {
    width: 425px;
    position: absolute !important;
    right: 0px;
    bottom: 0px;
    top: 65px;

    @media screen and (max-width: 576px) {
        right: auto;
        top: auto;
        bottom: auto;
    }
}

.stock-widget-modal {
    width: 600px;
    position: absolute !important;
    right: 0px;
    bottom: 0px;
    top: 65px;

    @media screen and (max-width: 576px) {
        right: auto;
        width: 100%;
        top: auto;
        bottom: auto;
    }
}

.market-depth-modal {
    width: 500px;
    position: absolute !important;
    right: 0px;
    bottom: 0px;
    top: 65px;

    @media screen and (max-width: 576px) {
        right: auto;
        top: auto;
        bottom: auto;
    }
}

.profile-menu-options {
    .for-mobile {
        display: none;

        @media screen and (max-width: 1200px) {
            display: block;
        }
    }
}

.mat-error {
    color: $red;
    line-height: 18px;
    font-size: 12px;
    text-align: left;
}

.status-cover {
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: bold;

    &.green {
        background-color: $lightGreen;
        color: $green;
    }

    &.red {
        background-color: $pink;
        color: $red;
    }
}

.ql-editor {
    min-height: 100px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
    box-shadow: 0 0 0 30px #f7f7f7 inset !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #E4E4E4;
    border-radius:10px;
}
.select-box{
    position: relative;
    .mat-error{
        position: relative;
        top:-18px;
    }
    mat-label{
        width:100%;
        display:inline-block;
        margin-bottom:15px;
        text-align: left;
    }
    .ng-select {
        box-shadow:none;
        margin-bottom:20px;
        &::before{
            position: absolute;
            right:10px;
            top:22px;
            content:"";
            border-color: #999 transparent transparent;
            border-style: solid;
            border-width: 5px 5px 2.5px;
        }
        &.ng-select-opened{
            &::before{
                border-color: transparent transparent #999;
                border-width: 0 5px 5px;
            }
            .ng-select-container {
                border-color: #e11f25!important;
                border-width:2px;
                box-shadow: none!important;
            }
        }
        &.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
            border-color: #e11f25!important;
            border-width:2px;
            box-shadow: none!important;
        }
        .ng-option-label{
            font-weight:400!important;
        }
        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
            color:#e11f25;
            background-color:#f7f7f7;
        }

        &.ng-select-disabled{
            .ng-select-container{
                opacity:0.4;   
                &:hover{
                    border:solid 1px #a5a5a5;
                }               
            }
        }
        &.invalid-select{
            .ng-select-container{
                border: 2px solid #a5a5a5;
            }
        }
        .ng-select-container{
            min-height:47px;
            position: relative;
            height:48px;
            overflow-y: auto;
            box-shadow: none;
            background: transparent;
            border: 1px solid #a5a5a5;
            transition:0.3s ease-in;
            &:hover{
                border:solid 2px #000;
            }
            .ng-value-container .ng-input>input{
                padding: 0;
                display: flex;
                align-items: center;
            }
            .ng-value-container{
                padding-right:30px;
                align-items: flex-start;
                .ng-value{
                    display: flex;
                    flex-direction: row-reverse;    
                    margin-top:3px;
                    margin-bottom:3px;
                    .ng-value-icon.left {
                        border-left: 1px solid #a5a5a5;
                        border-right:none;
                    }
                }
            }
        }
        .ng-arrow-wrapper {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display:none;
        }
        
    }
}
@import '../scss/var';

// Single-Axis Scroller
@mixin custom-scroller($axis, $brodness: 8px, $bgcolor: $primary) {
    @if ($axis=="x") {
        overflow-x: auto;
        overflow-y: hidden;
    }

    @if ($axis=="y") {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        width: $brodness;
        height: $brodness;
    }

    &::-webkit-scrollbar-track {
        width: $brodness;
        height: $brodness;
        border-radius: 20px 20px;
        background: $silver;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 20px 20px;
        background-color: $bgcolor;
        opacity: 0.5;
        width: $brodness;
        height: $brodness;

        &:hover {
            opacity: 1;
        }
    }
}

//   Two-Axis Scroller
@mixin scroller($brodness: 8px, $bgcolor: $primary) {
    overflow-y: auto;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: $brodness;
        height: $brodness;
    }

    &::-webkit-scrollbar-track {
        width: $brodness;
        height: $brodness;
        border-radius: 20px 20px;
        background: $silver;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 20px 20px;
        background-color: $bgcolor;
        opacity: 0.5;
        width: $brodness;

        &:hover {
            opacity: 1;
        }
    }
}

@mixin box-shadow {
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
}

@mixin fpos($a-item, $j-align) {
    @include flexbox;
    align-items: $a-item;
    justify-content: $j-align;
}

@mixin flexCenter($horizontal: true, $vertical: true) {
    display: flex;

    @if ($horizontal and $vertical) {
        align-items: center;
        justify-content: center;
    }

    @else if ($horizontal) {
        justify-content: center;
    }

    @else if ($vertical) {
        align-items: center;
    }
}


@mixin radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin transform($property) {
    transform: $property;
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
}

@mixin circle($size) {
    width: $size + px;
    height: $size + px;
    @include radius(50%);
}

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin falign($value) {
    @include flexbox;
    -webkit-box-align: $value;
    align-items: $value;
}

@mixin fpos($a-item, $j-align) {
    @include flexbox;
    align-items: $a-item;
    justify-content: $j-align;
}

@mixin text-truncate($line, $height: "") {
    display: block !important;
    display: -webkit-box !important;
    @include line-clamp($line);
    @include box-orient();
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: $height;
    min-height: $height;
}

@mixin line-clamp($line) {
    -webkit-line-clamp: $line;
    -moz-line-clamp: $line;
    -ms-line-clamp: $line;
    -o-line-clamp: $line;
    line-clamp: $line;
}

@mixin box-orient($value: vertical) {
    -webkit-box-orient: $value;
    -moz-box-orient: $value;
    -ms-box-orient: $value;
    -o-box-orient: $value;
    box-orient: $value;
}
@import '../scss/var';
@import '../scss/mix';

mat-form-field {
    width: 100%;
    margin-bottom: 10px;

    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }

    .mat-form-field-flex {
        align-items: center;
        .mat-form-field-outline {
            div {
                border-color: $input-border;
            }
        }

        &:hover {
            .mat-form-field-outline {
                div {
                    border-color: $black;
                }
            }
        }
    }

    &.mat-focused {
        .mat-form-field-flex {
            .mat-form-field-outline-thick {
                div {
                    border-color: $primary;
                }
            }
        }

        .mat-form-field-infix {
            .mat-form-field-label-wrapper {
                mat-label {
                    color: $primary;
                }

                label {
                    span {
                        color: $primary;
                    }
                }
            }
        }

        .mat-form-field-ripple {
            background-color: $primary !important;
        }
    }

    &.percentage-input {
        .mat-form-field-suffix {
            top: 0px;
        }
    }

    &.date-input {
        .mat-form-field-suffix {
            top: 6px;
            right: -9px;
        }
    }

    &.mat-form-field-appearance-outline {
        .mat-form-field-suffix {
            top: 7px;
            cursor: pointer;

            @media screen and (max-width: 576px) {
                top: 4px;
                right: -7px;
            }
        }
    }

    .suffix {
        position: relative;
        top: -8px;
    }
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: transparent !important;
}

.mat-card {
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2) !important;

    @media screen and (max-width: 576px) {
        padding: 10px;
    }
}

mat-icon,
.material-icons {
    user-select: none;
}

.mat-option {
    color: $black;

    &.mat-selected {
        .mat-option-pseudo-checkbox {
            &.mat-pseudo-checkbox-checked {
                background: $primary;
            }
        }

        .mat-option-text {
            color: $primary;
        }
    }

    .mat-option-text {
        font-size: 13px;
    }
}

.mat-checkbox {
    display: block;
    margin-bottom: 20px;

    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
        &.mat-primary {
            .mat-checkbox-background {
                background-color: $primary !important;
            }
        }
    }
}

.mat-checkbox-frame {
    border-color: $input-border;
    border-width: 1px !important;
}

.mat-checkbox-label {
    font-size: 14px;
}

.mat-form-field-infix {
    .mat-input-element {
        min-height: 21px;
        line-height: 20px;
        font-size: 14px;

        @media screen and (max-width: 576px) {
            font-size: 13px;
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .mat-select-value {
        line-height: 21px;
        color: $black;
    }

    .mat-select-disabled {
        span {
            color: rgba(0, 0, 0, 0.8);
        }
    }
}

.mat-accordion {
    .mat-expansion-panel {
        background-color: $white;
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
        border-radius: 4px !important;

        .mat-expansion-panel-header {
            border-radius: 4px;

            &.mat-expanded {
                padding-top: 16px;
                padding-bottom: 16px;
                height: auto;
            }

            .mat-expansion-indicator {
                margin-top: -5px;
            }
        }

        .mat-expansion-panel-header-title {
            font-weight: 500;
            font-size: 14px;

            .custom-margin {
                line-height: 20px;
            }
        }

        &:not(:nth-last-of-type(1)) {
            margin-bottom: 20px;
        }
    }
}

.mat-tab-group {
    background-color: $white;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);

    .mat-tab-label-container {
        .mat-tab-labels {
            border-bottom: 1px solid var(--light-gray);
            margin-bottom: 1px;

            .mat-tab-label {
                opacity: 1;
                height: auto;
                padding: 15px 20px;
                min-width: 80px;
                color: $gray;

                &.mat-tab-label-active {
                    color: $primary;
                }
            }

            .mat-ink-bar {
                background: $primary;
            }
        }
    }

    .mat-tab-body-content {
        padding: 20px;
    }

    &.mat-primary {
        .mat-ink-bar {
            background: $primary;
        }
    }
}
textarea {
    resize: none !important;
}

.textarea_autosize.mat-form-field {
    .mat-form-field-infix {
        height: auto !important;
        line-height: 1.45 !important;
        padding: 7px 2px !important;
        textarea.mat-input-element {
            margin: 0 0;
        }
    }
}
.mat-radio-button {
    margin-bottom:15px;
    &.mat-radio-disabled{
        .mat-radio-label-content {
            color: rgba(0,0,0,0.3);
        }
    }
    .mat-radio-label-content {
        font-size: 13px;
        color: $gray;
    }

    .mat-radio-outer-circle {
        border-width: 1px;
    }

    &.mat-radio-checked {
        font-weight: bold;

        .mat-radio-label {
            .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: $primary;
                }

                .mat-radio-inner-circle {
                    background-color: $primary;
                }
            }

            .mat-radio-label-content {
                color: $primary;
            }
        }
    }

    &.mat-accent {
        .mat-radio-ripple {
            .mat-ripple-element {
                &:not(.mat-radio-persistent-ripple) {
                    background-color: $primary;
                }
            }
        }
    }
}

.radio-buttons-wrapper {
    margin-bottom: 20px;

    .radio-buttons-heading {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $textColor;
    }

    .mat-radio-button {
        &:not(:nth-last-of-type(1)) {
            margin-right: 20px;
        }
    }
}

.mat-sort-header-container {
    justify-content: center !important;
}

.mat-select-placeholder {
    color: rgba(0, 0, 0, 0.8);
}

.mat-form-field-label {
    color: rgba(0, 0, 0, 0.8);
}

.mat-menu-panel {
    min-height: auto !important;
    background-color: $white;
    border: 1px solid $alto;
    border-radius: 5px;

    .mat-menu-content {
        color: $black;

        .mat-menu-item {
            padding: 10px 15px !important;
            height: auto !important;
            line-height: initial !important;
            background-color: transparent;

            &:hover {
                background-color: $layout-bg;
            }

            .mat-icon {
                display: inline-flex;
                font-size: 22px;
                margin-right: 15px;

                img {
                    width: 18px;
                    margin: auto;
                }
            }
        }
    }
}

.mat-toolbar-multiple-rows {
    height: 64px;

    @media screen and (max-width: 1600px) {
        height: 50px;
        min-height: 50px !important;
    }
}

.mat-list-item-content {
    @media screen and (max-width: 1600px) {
        padding: 0px 12px !important;
    }
}

.mat-input-element {
    &.mat-form-field-autofill-control {
        text-overflow: ellipsis;
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.7);
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-infix {
        padding: 14px 0 14px 0;

        @media screen and (max-width: 576px) {
            padding: 10px 0 10px 0;
        }
    }

    .mat-form-field-outline {
        top: 15px !important;
    }

    .mat-select-arrow-wrapper {
        color: var(--black);
        transform: none !important;
    }

    mat-label {
        font-size: 14px !important;
    }

    .mat-select-value {
        span {
            font-size: 14px !important;

            @media screen and (max-width: 576px) {
                font-size: 12px !important;
            }
        }
    }

    .mat-form-field-subscript-wrapper {
        padding: 0 10px 0px 0px !important;
        margin-top: 2px;
        position: inherit;
    }

    .mat-form-field-label {
        margin-top: -0.18em;

        @media screen and (max-width: 576px) {
            top: 26px;

            mat-label {
                font-size: 12px !important;
            }
        }
    }

    &.mat-form-field-can-float {
        &.mat-form-field-should-float {
            .mat-form-field-label {
                transform: translateY(-17px) scale(0.75) !important;
                color: $black;

                @media screen and (max-width: 576px) {
                    transform: translateY(-14px) scale(0.75) !important;
                }
            }
        }
    }

    &.mat-form-search {
        .mat-form-field-subscript-wrapper {
            margin-top: 0px;
        }
    }
}

.mat-snack-bar-container {
    height: 100%;
}

.mat-tab-list {
    .mat-tab-labels {
        display: inherit;
    }
}

.mat-sort-header-arrow {
    opacity: 0.5 !important;
    transform: translateY(0%) !important;
}

.mat-sort-header-sorted {
    .mat-sort-header-arrow {
        opacity: 1 !important;
        transform: translateY(0%) !important;
    }
}

.mat-select-search-inner {
    .mat-select-search-input {
        height: 48px !important;
    }
}

.mat-spinner {
    width: 50px !important;
    height: 50px !important;
    margin: auto;
    margin-bottom: 20px;

    svg {
        width: 50px !important;
        height: 50px !important;

        circle {
            stroke: $primary;
        }
    }
}

.mobile-number-input-cover {
    .mat-form-field-prefix {
        top: -1px !important;
        margin-right: 3px;

        @media screen and (max-width: 576px) {
            top: -2px !important;
        }
    }
}